import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BaseRoutingComponent } from './base-routing.component';

@NgModule({
  imports: [FormsModule],
  declarations: [BaseRoutingComponent],
  exports: [],
})
export class BaseRoutingModule {}
