import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

type Point = { x: number, y: number };
type CroppingConfig = { top: number, left: number, ratio: number };
type ImageCroppingConfig = { left: number, top: number, width: number, height: number, };

@Directive({
  selector: '[appDrawImage]',
})
export class DrawImageDirective implements AfterViewInit {
  @Input() appDrawImage: HTMLImageElement;
  @Input() faceLandmarks: { top: Point, bottom: Point, left: Point, right: Point, center: Point, faceBox: number[], boundingPoly: Point[] };

  @Output() croppingConfig: EventEmitter<CroppingConfig> = new EventEmitter<CroppingConfig>();

  canvas: HTMLCanvasElement;
  canvasContext: CanvasRenderingContext2D;

  croppingConfigObject: CroppingConfig;
  imageCroppingConfigObject: ImageCroppingConfig;

  constructor(elementRef: ElementRef<HTMLCanvasElement>) {
    this.canvas = elementRef.nativeElement;
    this.canvasContext = elementRef.nativeElement.getContext('2d');
  }

  ngAfterViewInit(): void {
    this.canvas.height = this.canvas.clientHeight;
    this.canvas.width = this.canvas.clientWidth;
    this.calculateImageConfig();
    this.drawImage();
  }

  calculateImageConfig(): void {
    const image = this.appDrawImage;
    const canvasAspectRatio = this.canvas.clientWidth / this.canvas.clientHeight;
    // Minimum cropping padding to be added on all side, in percentage of faceWidth and faceHeight
    const faceWidthPreCropping = this.faceLandmarks.right.x - this.faceLandmarks.left.x;
    const faceHeightPreCropping = this.faceLandmarks.bottom.y - this.faceLandmarks.top.y;
    const minimumCroppingPaddingTop = faceHeightPreCropping * 0.1;
    const minimumCroppingPaddingLeft = faceWidthPreCropping * 0.1;
    // Add 35px(Relative) to the image height as bottom padding
    const extraPaddingToBottom = (25 * (image.height / this.canvas.clientHeight));

    let width = minimumCroppingPaddingLeft + faceWidthPreCropping + minimumCroppingPaddingLeft;
    let height = minimumCroppingPaddingTop + faceHeightPreCropping + minimumCroppingPaddingTop + extraPaddingToBottom;

    const faceAspectRatio = width / height;
    // If face is wider then add extra space to top and increase height
    // else add extra space to left and increase width
    let left = this.faceLandmarks.left.x - minimumCroppingPaddingLeft;
    let top = this.faceLandmarks.top.y - minimumCroppingPaddingTop;

    if (faceAspectRatio > canvasAspectRatio) {
      const newHeight = width / canvasAspectRatio;
      top -= (newHeight - height) / 2;
      height = newHeight;
    } else {
      const newWidth = height * canvasAspectRatio;
      left -= (newWidth - width) / 2;
      width = newWidth;
    }
    if (Math.sign(left) === -1) {
      left = 0;
    } else if ((left + width) > image.width) {
      left = image.width - width;
    }
    this.croppingConfigObject = { left, top, ratio: this.canvas.clientHeight / height };
    this.imageCroppingConfigObject = { left, top, width, height };
    this.croppingConfig.emit(this.croppingConfigObject);
  }

  drawImage(): void {
    const image = this.appDrawImage;

    this.canvasContext.drawImage(
      image,
      this.imageCroppingConfigObject.left,
      this.imageCroppingConfigObject.top,
      this.imageCroppingConfigObject.width,
      this.imageCroppingConfigObject.height,
      0,
      0,
      this.canvas.clientWidth,
      this.canvas.clientHeight,
    );
    // this.drawLandmarks(this.croppingConfigObject);
  }

  drawLandmarks(config: any): void {
    this.canvasContext.strokeStyle = '#00ff00';
    this.canvasContext.beginPath();
    this.canvasContext.arc(
      (this.faceLandmarks.top.x - config.left) * config.ratio,
      (this.faceLandmarks.top.y - config.top) * config.ratio,
      5,
      0,
      2 * Math.PI);
    this.canvasContext.stroke();
    this.canvasContext.beginPath();
    this.canvasContext.arc(
      (this.faceLandmarks.left.x - config.left) * config.ratio,
      (this.faceLandmarks.left.y - config.top) * config.ratio,
      5,
      0,
      2 * Math.PI);
    this.canvasContext.stroke();
    this.canvasContext.beginPath();
    this.canvasContext.arc(
      (this.faceLandmarks.bottom.x - config.left) * config.ratio,
      (this.faceLandmarks.bottom.y - config.top) * config.ratio,
      5,
      0,
      2 * Math.PI);
    this.canvasContext.stroke();
    this.canvasContext.beginPath();
    this.canvasContext.arc(
      (this.faceLandmarks.right.x - config.left) * config.ratio,
      (this.faceLandmarks.right.y - config.top) * config.ratio,
      5,
      0,
      2 * Math.PI);
    this.canvasContext.stroke();
    this.canvasContext.beginPath();
    this.canvasContext.arc(
      (this.faceLandmarks.center.x - config.left) * config.ratio,
      (this.faceLandmarks.center.y - config.top) * config.ratio,
      5,
      0,
      2 * Math.PI);
    this.canvasContext.stroke();

    this.canvasContext.beginPath();
    this.canvasContext.rect(
      this.faceLandmarks.faceBox[0] * config.ratio,
      this.faceLandmarks.faceBox[1] * config.ratio,
      (this.faceLandmarks.faceBox[2] - this.faceLandmarks.faceBox[0]) * config.ratio,
      (this.faceLandmarks.faceBox[3] - this.faceLandmarks.faceBox[1]) * config.ratio,
    );
    this.canvasContext.stroke();
  }
}
