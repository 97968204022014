<div class="font-noto tw-flex tw-items-center tw-relative tw-px-2 tw-py-4">
  <img class="tw-rounded-full tw-absolute tw-mb-20 tw-w-[120px] tw-z-[100] tw-top-[-60px]" src="https://storage.googleapis.com/heallocontent/app/img/dr-charu-cropped.png" (click)="handleClick()" />
  <div class="tw-absolute tw-p-1 tw-rounded-full tw-bg-gray-100 tw-flex tw-justify-center tw-items-center tw-z-[100] tw-top-[15px] tw-right-[10px] close-sheet" style="width: 35px; height: 35px" (click)="closeBottomSheet()">
    <div class="tw-text-black tw-text-500 tw-font-bold">&#10005;</div>
  </div>
  <div class="tw-w-full tw-bg-white rounded-lg tw-p-2 tw-flex tw-flex-col tw-pt-10" (click)="handleClick()">
    <div>
      <h2 class="tw-text-lg tw-font-semibold tw-text-700 tw-mt-2" *ngIf="appConfig.Shared.Regimen.Class.FACE === regimenClass" i18n="@@yourFaceChatIsIncomplete">
        Your face chat is incomplete
      </h2>
      <h2 class="tw-text-lg tw-font-semibold tw-text-700 tw-mt-2" *ngIf="appConfig.Shared.Regimen.Class.HAIR === regimenClass" i18n="@@yourHairChatIsIncomplete">
        Your hair chat is incomplete
      </h2>
      <p class="tw-my-2 tw-text-400 tw-text-gray-500" i18n="@@doctorWaitingForChat">
        Your doctor is waiting for some answers to create the right treatment
        kit for you.
      </p>
    </div>
    <cs-button class="tw-my-2" [class]="'w100 primary btn btn-padding tw-px-6 btn-float'"><cs-button-text><span class="tw-font-body tw-tracking-normal" i18n="@@continueChat">Continue</span><span>&nbsp; ></span></cs-button-text></cs-button>
  </div>
</div>
