import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { EventLoggerService } from '@services/event-logger-service';

@Component({ selector: 'base-routing', template: '<div></div>' })
export class BaseRoutingComponent {
  constructor(private conn: ConnectionService,
    private router: Router,
    private appWebBridge: AppWebBridgeService,
    private eventLogger: EventLoggerService) {
  }

  async ngOnInit(): Promise<void> {
    const user = this.conn.getActingUser();
    if (!user) {
      if (this.appWebBridge.isAppWebBridgeLoaded()) {
        this.router.navigate(['/onboarding/number']);
      } else {
        this.router.navigate(['/language']);
      }
      return;
    }
    await this.router.navigate(['/user'], { queryParams: { tab: 'home', init: 'true' }, replaceUrl: true });
  }
}
