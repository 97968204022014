import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'date' })
export class DatePipeService implements PipeTransform {
  pipe: any = new DatePipe('en-US');

  transform(date: any[], format: string): void {
    return this.pipe.transform(date, format);
  }
}
